<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo align-items-center">
      <b-img :src="logo" alt="Ecoplot" width="40" height="40"></b-img>
      <h2 class="brand-text text-primary ml-1 mb-0">Ecoplot Pro</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
        <p class="mb-2">Oops! 😖 The requested URL was not found on this server.</p>

        <b-button variant="primary" class="mb-2 btn-sm-block" :to="{ path: '/' }"> Back to home </b-button>

        <!-- image -->
        <component :is="skin === 'dark' ? 'error-dark' : 'error-light'" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '../../@core/layouts/components/Logo.vue'
import ErrorLight from '/static/images/pages/error.svg'
import ErrorDark from '/static/images/pages/error-dark.svg'

export default {
  components: { VuexyLogo, BLink, BButton, BImg, ErrorLight, ErrorDark },
  data() {
    return {
      logo: require('/static/besec.png'),
      // errorLight: require('/static/images/pages/error.svg'),
      // errorDark: require('/static/images/pages/error-dark.svg'),
    }
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-misc.scss';
</style>
